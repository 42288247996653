import * as React from 'react';
import { PageProps } from 'gatsby';
import { MDXProvider } from '@mdx-js/react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { MdxLink } from 'gatsby-theme-i18n';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import getPagePath from '../../utils/getPagePath';
import { graphql2job, GraphqlJob, PageContextProps, SiteQueryProps } from '../../utils/graphql';
import DefaultLayout from '../layouts/default-layout';
import Cities from './Cities';
import Contracts from './Contracts';
import JobApplication from './JobApplication';
import * as Colors from '../Colors';
import SharingBar from '../SharingBar';
import NotTranslated from '../NotTranslated';

const components = {
  a: MdxLink,
};

type JobQueryProps = {
  site: SiteQueryProps;
  mdx: GraphqlJob;
};

type JobProps = PageProps<JobQueryProps, PageContextProps>;

const Job: React.VoidFunctionComponent<JobProps> = ({ data: { site, mdx }, pageContext }) => {
  const { t } = useTranslation('jobs');

  const job = React.useMemo(() => (mdx ? graphql2job(mdx) : undefined), [mdx]);

  const pagePath = React.useMemo(() => getPagePath(pageContext, job), [pageContext, job]);

  if (job) {
    const headTitle = t('suffixed-head-title', { title: job.title, cities: job.cities.join(', ') });

    return (
      <>
        <DefaultLayout headTitle={headTitle} headDescription={headTitle} lang={pageContext.locale}>
          <article
            itemScope
            itemType="https://schema.org/JobPosting"
            id="job"
            className="text-black"
          >
            <header>
              <link
                itemProp="hiringOrganization"
                href={`${site.siteMetadata.siteUrl}#organization`}
              />
              <meta itemProp="url" content={`/${pageContext.locale}${pagePath}`} />
              <meta itemProp="datePosted" content={dayjs(job.date).format('YYYY-MM-DD')} />
              {job.body && <meta itemProp="description" content={job.body} />}
            </header>
            <section className="container mt-5 px-4 pt-5 sm:px-8">
              <header className="">
                <p className="my-0 text-center text-xl font-medium sm:text-2xl">
                  {t('header-title')}
                </p>
                <h1 className="mb-3 mt-2" itemProp="title">
                  {job.title}
                </h1>
                <div className="mb-9 text-center text-6xl font-light leading-tight sm:text-7xl">
                  <Contracts contracts={job.contracts} withMicroData />
                  {job.cities.length > 0 && job.contracts.length > 0 ? ', ' : ''}
                  <Cities cities={job.cities} withMicroData />
                </div>
                <div className="flex flex-wrap items-center justify-center">
                  {job.domains.map((domain) => {
                    return (
                      <p
                        key={domain}
                        className="mx-2 mb-4 bg-reacteev-blue px-3 py-1 font-medium text-white"
                      >
                        {`#${domain}`}
                      </p>
                    );
                  })}
                </div>
                <SharingBar
                  className="-mb-8 mt-12 justify-end"
                  siteUrl={site.siteMetadata.siteUrl}
                  socialIcons={site.siteMetadata.social}
                  t={t}
                />
              </header>
            </section>
            <section className="container px-4 sm:px-8">
              <div className="prose w-full">
                <MDXProvider components={components}>
                  <MDXRenderer>{job.body}</MDXRenderer>
                </MDXProvider>
              </div>
            </section>
            <JobApplication
              ctaButton={t('render.button.applyToTheOffer')}
              ctaTheme={{
                class: Colors.WHITE_ON_BLUE,
                pseudoClass: Colors.WHITE_ON_BLUE_PSEUDO_CLASS,
              }}
              formTitle={t('render.button.applyToTheOffer')}
              emailSubjet={t('email.subject', { title: job.title, cities: job.cities.join(', ') })}
              showTeamPhoto
            />
          </article>
        </DefaultLayout>
      </>
    );
  }
  return <NotTranslated pageContext={pageContext} subject={t('notTranslatedSubject')} />;
};

export default Job;
